<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button default-href="/conversation">Back</ion-back-button>
					<ion-title>Preview</ion-title>
				</ion-buttons>
				<div class="infoImage" slot="end">
					<span>( Double click to zoom )</span>
					<!-- <ion-chip color="ari"
						><a :href="url" target="_blank">Download</a></ion-chip
					> -->
				</div>
			</ion-toolbar>
		</ion-header>
		<ion-slides :options="slideOpts" class="fullDisplay">
			<ion-slide>
				<div class="swiper-zoom-container" data-swiper-zoom="3">
					<img class="imgPopup" :src="url" />
				</div>
			</ion-slide>
		</ion-slides>
	</ion-page>
</template>

<script>
import {
	IonHeader,
	IonToolbar,
	IonButtons,
	// IonChip,
	IonSlides,
	IonSlide,
	IonPage,
	IonBackButton,
	IonTitle,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
	name: "Modal",
	components: {
		IonHeader,
		IonToolbar,
		IonButtons,
		// IonChip,
		IonSlides,
		IonSlide,
		IonPage,
		IonBackButton,
		IonTitle,
	},
	data() {
		const route = useRoute();
		const url = ref("#");
		if (route.query.url != null) {
			url.value = route.query.url;
		}
		const slideOpts = {
			zoom: {
				maxRatio: 5,
			},
		};
		return {
			url,
			slideOpts,
			content: "Content",
		};
	},
});
</script>

<style scoped>
.infoImage {
	margin: 0 20px;
}
.infoImage > ion-chip > a {
	text-decoration: none;
}
.infoImage > span {
	margin-right: 20px;
}
</style>
